import React from 'react'
import tw, { css } from 'twin.macro'
import { Facebook, Twitter, Linkedin } from 'react-feather'
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from 'react-share'
import { global } from '../styles/global'

const ShareButtons = ({ url, title, description }) => {
  const ShareIcon = css`
    ${tw`mr-2.5 lg:mt-2.5 fill-primary`}
    &:hover {
      ${tw`transition-colors duration-500 ease-in-out fill-secondary stroke-0`}
    }
  `

  return (
    <div css={[global`layout.container`]}>
      <div css={tw`relative flex flex-row items-start mb-10 lg:(absolute flex flex-col)`}>
        <FacebookShareButton url={url} quote={description}>
          <Facebook strokeWidth={1} css={ShareIcon} />
        </FacebookShareButton>

        <LinkedinShareButton url={url} title={title} summary={description}>
          <Linkedin strokeWidth={1} css={ShareIcon} />
        </LinkedinShareButton>

        <TwitterShareButton url={url} title={description}>
          <Twitter strokeWidth={1} css={ShareIcon} />
        </TwitterShareButton>
      </div>
    </div>
  )
}

export default ShareButtons
